import React, { useState } from "react";
import "./Resume.css";
import JobList from "./JobList";
import JobModal from "./JobModal";
import resumeData from "../../assets/resumeData.json";
import { Typography } from "@mui/material";

const Resume = () => {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleJobClick = (jobId) => {
    setSelectedJobId(jobId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedJobId(null);
    setIsModalOpen(false);
  };

  return (
    <div className="resume-container" id="resume">
      <div className="resume-content">
        <Typography variant="h2">Selected Work</Typography>
        <p className="resume-description">
          I&apos;m an AI enthusiast who has built several GenAI apps alongside a long career in ML.
          I have over a decade of marketplace experience driving product innovation across multiple
          industries: travel, housing, e-commerce, fashion, and QSR.
        </p>

        <JobList jobs={resumeData} onJobClick={handleJobClick} />
      </div>

      <JobModal
        jobId={selectedJobId}
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default Resume;