import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import './StickyHeader.css';

const StickyHeader = ({ isVisible, scrollToContact }) => {
  return (
    <Box className={`sticky-header ${isVisible ? 'visible' : ''}`}>
      <Typography variant="h5" className="header-name">
        William Keck
      </Typography>
      <Button 
        className="header-contact-button"
        onClick={scrollToContact}
        variant="solidLight"
        sx={{
          height: 40,
          p: '0 1rem',
        }}
      >
        Get in Touch
      </Button>
    </Box>
  );
};

export default StickyHeader;