import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import "./JobTile.css";

const JobTile = ({ job, onClick }) => {
  const tileRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const node = tileRef.current;
    const observerOptions = { threshold: 0.1 };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (node) observer.observe(node);

    return () => {
      if (node) observer.unobserve(node);
    };
  }, []);

  return (
    <Box
      ref={tileRef}
      className={`job-tile ${isVisible ? "visible" : ""}`}
      onClick={() => onClick(job.id)}
    >
      <Card className="tile-content">
        <Box className="job-image-wrapper">
          <img
            src={
              job.imagePath
                ? `${process.env.PUBLIC_URL}${job.imagePath}`
                : `${process.env.PUBLIC_URL}/images/defaultLogo.jpeg`
            }
            alt={`${job.company} logo`}
            className="job-image"
          />
        </Box>
        <Box className="company-name-wrapper">
          <Typography variant="body4" className="company-name" sx={{ fontWeight: "bold" }}>
            {job.company}
          </Typography>
        </Box>
        <CardContent className="job-details">
          <Typography variant="body4" className="job-title">
            {job.title}
          </Typography>
          {job.domain && (
            <Typography variant="body4" className="domain">
              {job.domain}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default JobTile;