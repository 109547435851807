import React, { useState, useEffect, useRef } from 'react';
import {
  Drawer,
  IconButton,
  Box,
  Typography,
  Divider,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactMarkdown from 'react-markdown';

const SharedDrawer = ({
  open,
  onClose,
  title,
  content,
  navigationContent = null,
  headerActions = null,
  image = null,
  about = null,
  mission = null,
  team = null
}) => {
  const theme = useTheme();
  const [currentContent, setCurrentContent] = useState(content);
  const [showNavigation, setShowNavigation] = useState(true);
  const drawerRef = useRef(null);

  // Determine if we're on desktop
  const isDesktop = useMediaQuery('(min-width:768px)');

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  // On open, scroll drawer to top
  useEffect(() => {
    if (open && drawerRef.current) {
      const drawerPaper = drawerRef.current.querySelector('.MuiDrawer-paper');
      if (drawerPaper) {
        drawerPaper.scrollTop = 0;
      }
    }
  }, [open, currentContent]);

  // Body scroll-lock logic
  useEffect(() => {
    const body = document.body;
    const bodyScrollY = window.scrollY;

    if (open) {
      body.style.position = 'fixed';
      body.style.width = '100%';
      body.style.top = `-${bodyScrollY}px`;
    } else {
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.width = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    return () => {
      body.style.position = '';
      body.style.width = '';
      body.style.top = '';
    };
  }, [open]);

  const handleNavigation = (newContent) => {
    setCurrentContent(newContent);
    setShowNavigation(false);
  };

  const handleBack = () => {
    setShowNavigation(true);
    setCurrentContent(content);
  };

  return (
    <Drawer
      anchor={isDesktop ? 'right' : 'bottom'}
      open={open}
      onClose={onClose}
      ref={drawerRef}
      PaperProps={{
        sx: {
          // Keep dynamic sizing
          width: isDesktop ? '65%' : '100%',
          height: isDesktop ? '100vh' : '85vh',
          // Preserve unique border radius for desktop vs mobile
          borderRadius: isDesktop ? '16px 0 0 16px' : '16px 16px 0 0'
        }
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            transition: 'opacity 200ms cubic-bezier(0.32, 0.72, 0, 1)'
          }
        }
      }}
    >
      {/* HEADER AREA */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          bgcolor: theme.palette.accent.main,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {!showNavigation && (
          <IconButton
            onClick={handleBack}
            sx={{ color: theme.palette.background.paper }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          sx={{
            flex: 1,
            textAlign: 'center',
            color: theme.palette.background.paper,
            px: 6
          }}
        >
          {!showNavigation ? title : navigationContent?.title || title}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ color: theme.palette.background.paper }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* CONTENT AREA */}
      <Box
        sx={{
          p: 3,
          width: '100%',
          maxWidth: '100%',
          overflowX: 'hidden',
          '& img': {
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain'
          }
        }}
      >
        {showNavigation && navigationContent ? (
          <>
            {about && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    About
                  </Typography>
                  <Typography>{about}</Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
              </>
            )}

            {mission && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Mission
                  </Typography>
                  <Typography>{mission}</Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
              </>
            )}

            {team && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Team
                  </Typography>
                  {team}
                </Box>
                <Divider sx={{ my: 2 }} />
              </>
            )}

            <Typography variant="h6" gutterBottom>
              {navigationContent.title || 'Projects'}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: '1fr',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)'
                },
                gap: 2,
                mt: 2
              }}
            >
              {navigationContent.items.map((item, index) => (
                <Box
                  key={index}
                  onClick={() => handleNavigation(item.content)}
                  sx={{
                    // This "paper" color is typically the same as Drawer background,
                    // but you can keep it if you want a subtle card effect
                    backgroundColor: theme.palette.background.paper,
                    p: 3,
                    borderRadius: 1,
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    textAlign: 'center',
                    boxShadow: 2,
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: 4
                    }
                  }}
                >
                  <Typography>{item.title}</Typography>
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <Box sx={{ width: '100%', maxWidth: '800px', mx: 'auto' }}>
            {image && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 3
                }}
              >
                <Box
                  component="img"
                  src={image}
                  alt={title}
                  sx={{
                    maxWidth: '100%',
                    height: 'auto',
                    display: 'block',
                    borderRadius: 1,
                    objectFit: 'contain'
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                '& h2': {
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  marginTop: '2rem',
                  marginBottom: '1rem',
                  color: theme.palette.text.primary
                },
                '& p': {
                  fontSize: '1rem',
                  lineHeight: 1.6,
                  marginBottom: '1.5rem',
                  color: theme.palette.text.primary
                },
                '& ul': {
                  listStyleType: 'disc',
                  marginLeft: '2rem',
                  marginTop: '1rem',
                  marginBottom: '1.5rem'
                },
                '& li': {
                  marginBottom: '0.75rem',
                  paddingLeft: '0.5rem',
                  lineHeight: 1.6,
                  fontSize: '1rem',
                  color: theme.palette.text.primary,
                  '&::marker': {
                    color: theme.palette.text.primary
                  }
                }
              }}
            >
              <ReactMarkdown>{currentContent || ''}</ReactMarkdown>
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default SharedDrawer;