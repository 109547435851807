import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SharedDrawer from '../Shared/SharedDrawer';
import { mapProjectsToJobById } from '../../utils/mapProjectsToJobById';

const TeamDisplay = ({ size }) => (
  <Box display="flex" alignItems="center">
    {Array.from({ length: parseInt(size, 10) }).map((_, index) => (
      <PersonIcon
        key={index}
        fontSize="small"
        sx={{ color: 'var(--color-accent-color)', mr: 0.5 }}
      />
    ))}
  </Box>
);

const JobModal = ({ jobId, open, onClose }) => {
  const [job, setJob] = useState(null);

  useEffect(() => {
    const fetchJobData = async () => {
      if (open && jobId) {
        try {
          const jobData = await mapProjectsToJobById(jobId);
          setJob(jobData);
        } catch (error) {
          console.error('Failed to load job data:', error);
        }
      }
    };

    fetchJobData();

    if (!open) {
      setJob(null);
    }
  }, [open, jobId]);

  if (!open || !job) return null;

  const isSingleProject = job.projects && job.projects.length === 1;

  return (
    <SharedDrawer
      open={open}
      onClose={onClose}
      title={job.company}
      content={isSingleProject ? job.projects[0].content : job.description}
      about={job.about}
      mission={job.mission}
      team={job['team-size'] > 0 && <TeamDisplay size={job['team-size']} />}
      navigationContent={
        !isSingleProject && job.projects && job.projects.length > 0
          ? {
              title: 'Projects',
              items: job.projects.map((project) => ({
                title: project.title,
                content: project.content,
              })),
            }
          : null
      }
    />
  );
};

export default JobModal;