import React from "react";
import "./Articles.css";

const ArticleCard = ({ article, onClick }) => {
  return (
    <div
      className="article-card"
      onClick={onClick}
      role="button"
      aria-label={`View article: ${article?.data?.title || "Untitled Article"}`}
    >
      {article?.data?.image ? (
        <img
          src={article.data.image}
          alt={article.data.title}
          className="article-image"
        />
      ) : (
        <div className="article-image-placeholder">
          {article?.data?.title || "Untitled"}
        </div>
      )}
      <div className="article-content">
        <h3 className="article-title">{article?.data?.title}</h3>
        <p className="article-description">{article?.data?.description}</p>
      </div>
    </div>
  );
};

export default ArticleCard;