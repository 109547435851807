import React, { useRef, useState, useEffect } from "react";
import "./Carousel.css";

const Carousel = ({ children, className = "" }) => {
  const containerRef = useRef(null);
  const [centerItems, setCenterItems] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    // Measure total width of all child nodes
    let totalWidth = 0;
    containerRef.current.childNodes.forEach((child) => {
      totalWidth += child.scrollWidth; 
    });

    // Compare to container's width
    const containerWidth = containerRef.current.clientWidth;

    // If totalWidth < containerWidth, center them. Otherwise, left-justify
    setCenterItems(totalWidth <= containerWidth);
  }, [children]);

  const justifyStyle = centerItems ? "center" : "flex-start";

  return (
    <div className="carousel-container">
      <div 
        className={`carousel ${className}`}
        ref={containerRef}
        style={{ justifyContent: justifyStyle }}
      >
        {children}
      </div>
    </div>
  );
};

export default Carousel;