// Books.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import Carousel from '../Shared/Carousel';
import BookCard from './BookCard';
import { fetchBookDetails } from '../../utils/bookAPI';
import booksData from '../../data/books.json';
import './Books.css';  

const Books = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBooks = async () => {
      const enrichedBooks = await Promise.all(
        booksData.books.map(async (book) => {
          const bookDetails = await fetchBookDetails(book.isbn);
          return bookDetails ? { ...book, ...bookDetails } : null;
        })
      );
      setBooks(
        enrichedBooks
          .filter(Boolean)
          .sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded))
      );
      setLoading(false);
    };

    fetchBooks();
  }, []);

  if (loading) {
    return (
      <Box component="section" className="books-section">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      component="section" 
      id="books" 
      className="books-section"
    >
      <Typography variant="h2" sx={{ color: 'var(--color-light)' }}>
        My 2025 Books
      </Typography>

      {/* Carousel or any layout below */}
      <Carousel>
        {books.map((book) => (
          <BookCard key={book.isbn} book={book} />
        ))}
      </Carousel>
    </Box>
  );
};

export default Books;