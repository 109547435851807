// theme.js
import { createTheme } from "@mui/material/styles";

// Helper function to get CSS variable values
const getCSSVar = (varName) => {
  // Get the value from the :root CSS variables
  const value = getComputedStyle(document.documentElement)
    .getPropertyValue(varName)
    .trim();
  return value || '#000000'; // Fallback color if variable isn't found
};

const theme = createTheme({
  palette: {
    primary: {
      main: getCSSVar('--color-primary'),
    },
    secondary: {
      main: getCSSVar('--color-secondary'),
    },
    // Extra 'accent' color if you need one (e.g. for headers in drawers)
    accent: {
      main: getCSSVar('--color-secondary'), 
    },
    background: {
      default: getCSSVar('--color-lightest'),      // page backgrounds
      paper: getCSSVar('--color-card-background'), // "paper" surfaces (drawers, cards, etc.)
    },
    text: {
      primary: getCSSVar('--color-text-default'),
      secondary: getCSSVar('--color-text-on-light'),
    },
    error: {
      main: getCSSVar('--color-error'),
    },
    warning: {
      main: getCSSVar('--color-warning'),
    },
    success: {
      main: getCSSVar('--color-success'),
    },
  },
  typography: {
    fontFamily: "'museo-sans', sans-serif",
    fontSize: 16,
    h1: {
      fontSize: "2.25rem",
      fontWeight: 'bold',
      margin: 0,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 'bold',
      textTransform: 'uppercase',
      testAlign: 'center',
      marginBottom: '1.25rem',
    },
    h6: {
      fontSize: "1.25rem",
      color: getCSSVar('--color-text-default'),
    },
    body1: {
      fontSize: "1rem",
      color: getCSSVar('--color-text-default'),
    },
    body2: {
      fontSize: "0.875rem",
      color: getCSSVar('--color-text-default'),
    },
    body3: {
      fontSize: "0.74rem", // Smaller than body2
      lineHeight: 1.43,
      color: getCSSVar('--color-text-default'),
      marginBottom: getCSSVar('--spacing-small'),
      display: 'block',
    },
    body4: {
      fontSize: "15px", // Smaller font
      color: getCSSVar('--color-text-default'),
      fontWeight: 'normal',
      lineHeight: 1.4,
      display: 'block',
      margin: 0, // Remove all margins
      padding: 0, // Remove all paddings
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontWeight: "bold",
          fontSize: "0.8rem",
          padding: "0.6rem 1rem",
          borderRadius: getCSSVar('--border-radius'),
          boxShadow: "none",
          // By default, if you do <Button>, we use the palette primary color on hover
          '&:hover': {
            backgroundColor: getCSSVar('--color-darkest'),
          },
        },
      },
      // CUSTOM BUTTON VARIANTS
      variants: [
        // 1) A "ghost" style that uses primary-text as border + text color
        {
          props: { variant: "ghostPrimary" },
          style: {
            backgroundColor: "transparent",
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: getCSSVar('--color-primary-text'),
            border: `1px solid ${getCSSVar('--color-primary-text')}`,
            '&:hover': {
              backgroundColor: "--color-darkest",
              color: getCSSVar('--color-lightest'),
            },
            '&:active, &:focus': {
              backgroundColor: "transparent",
              color: getCSSVar('--color-primary-text'),
            },
          },
        },
        // 2) A solid accent style that uses '--color-primary-text' as background
        {
          props: { variant: "solidAccent" },
          style: {
            backgroundColor: getCSSVar('--color-darkest'),
            textTransform: 'uppercase',
            fontWeight: 'bold',
            border: '1px solid transparent',
            color: getCSSVar('--color-lightest'), // For contrast
            '&:hover': {
              backgroundColor: getCSSVar('--color-lightest'),
              color: getCSSVar('--color-darkest'),
              border: `1px solid ${getCSSVar('--color-darkest')}`, // optional border
            },
          },
        },
        // 3) A solid light style that uses '--color-card-background' as background
        {
          props: { variant: 'solidLight' },
          style: {
            backgroundColor: getCSSVar('--color-lightest'), 
            color: getCSSVar('--color-darkest'),
            textTransform: 'uppercase',     
            fontWeight: 'bold',
            border: `2px solid ${getCSSVar('--color-lightest')}`, // optional border
            '&:hover': {
              backgroundColor: getCSSVar('--color-highlight'), 
              color: getCSSVar('--color-card-background'), 
            },
          },
        },
        // 4) A blue button for the Hero section
        {
          props: { variant: 'heroAccent' },
          style: {
            backgroundColor: getCSSVar('--color-primary'), 
            color: getCSSVar('--color-lightest'),
            textTransform: 'uppercase',     
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: getCSSVar('--color-lightest'), 
              color: getCSSVar('--color-primary'), 
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: getCSSVar('--color-text-default'),
          },
          '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: getCSSVar('--color-medium'),
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: getCSSVar('--color-primary'),
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: getCSSVar('--color-primary'),
            },
          },
          '& .MuiInputLabel-root': {
            color: getCSSVar('--color-medium'),
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: getCSSVar('--color-primary'),
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            borderColor: getCSSVar('--color-medium'),
          },
        },
      },
    },
    // Global Drawer style overrides:
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: getCSSVar('--color-card-background'),
          borderRadius: getCSSVar('--border-radius'),
          border: 'none',
          overflowY: 'auto',
          transition: 'transform 400ms cubic-bezier(0.32, 0.72, 0, 1) !important',
        },
      },
    },
  },
});

export default theme;