import React from "react";
import { Box } from "@mui/material";
import "./LifeMap.css"; // Import CSS from the styles folder

const cities = [
  { name: "PARIS", years: "4 years", image: "paris.png" },
  { name: "LONDON", years: "5 years", image: "london.png" },
  { name: "NYC", years: "4 years", image: "newyork.png" },
  { name: "ANTIBES", years: "1.5 years", image: "antibes.png" },
  { name: "SEATTLE", years: "2 years", image: "seattle.png" },
  { name: "AUSTIN", years: "2 years", image: "austin.png" },
  { name: "MIAMI", years: "1 year", image: "miami.png" },
  { name: "ATLANTA", years: "14 years", image: "atlanta.png" },
  { name: "ST. LOUIS", years: "2 years", image: "stlouis.png" },
];

const LifeMap = () => {
  return (
    <Box className="life-map">
      {cities.map((city, index) => (
        <Box
          key={index}
          className="city-tile"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/${city.image})`,
          }}
        >
          <Box className="city-overlay">
            <div className="city-name">{city.name}</div>
            <div className="city-years">{city.years}</div>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default LifeMap;