import React from "react";
import { Box, Button, Typography, Grid2 } from "@mui/material";
import "./InterviewSection.css";

const InterviewSection = () => {
 const interviewUrl = "https://interview.willkeck.com";
 
 const handleStartInterview = () => {
   window.location.href = interviewUrl;
 };
 
 return (
   <Box className="interview-section">
     <Grid2
       container
       spacing={6}
       className="interview-grid"
       sx={{ maxWidth: '768px', margin: '0 auto' }}
     >
       <Grid2 item xs={12} md={9} className="image-column">
         <Box className="image-container" onClick={handleStartInterview}>
           <img
             src="/images/aiinterviewexample.png"
             alt="AI Interview Example"
             className="interview-image"
           />
         </Box>
       </Grid2>
       
       <Grid2 item xs={12} md={3} className="text-column">
        <Box>
            <Typography variant="h2" color="primary" className="mobile-center">
              WILLIAM AI
            </Typography>
            
            <Typography variant="body3">
              Hands-on AI development taught me more than any course. I built this
              interview app from scratch to understand AI fundamentals firsthand.
            </Typography>

            <Typography variant="body3" fontWeight="bold">
              User Experience
            </Typography>
            <Typography variant="body3">
              Text and voice chat using AI to create natural, conversational interviews.
            </Typography>

            <Typography variant="body3" fontWeight="bold">
              Technology Leveraged
            </Typography>
            <Typography variant="body3">
              Built with LLMs, RAG, embeddings, vector stores and voice cloning to accurately draw
              from my background and experience in a personalized way.
            </Typography>
        </Box>

        <Box className="interview-button">
          <Button onClick={handleStartInterview} variant="heroAccent" sx={{ marginTop: "1rem" }}>
             Start Interviewing
          </Button>
        </Box>
       </Grid2>
     </Grid2>
   </Box>
 );
};

export default InterviewSection;