import React from 'react';
import {
  CookingPot,
  BookOpen,
  Wine,
  Target,
  Dog,
  Code,
  PenLine,
  Icon
} from 'lucide-react';
import { golfDriver, tennisRacket } from '@lucide/lab';
import Carousel from '../Shared/Carousel';
import { Box, Typography } from '@mui/material';
import './Hobbies.css';

const HobbyCard = ({ icon: IconComponent, name, isBeta, isLabIcon }) => (
  <div className="hobby-card">
    <div className="hobby-icon-wrapper">
      {isLabIcon ? (
        <Icon iconNode={IconComponent} size={48} className="hobby-icon" />
      ) : (
        <IconComponent size={32} className="hobby-icon" />
      )}
    </div>
    <p className="hobby-name">
      {name}
      {isBeta && <span className="beta-tag">beta</span>}
    </p>
  </div>
);

const Hobbies = () => {
  const hobbies = [
    { name: 'Cooking', icon: CookingPot },
    { name: 'Reading', icon: BookOpen },
    { name: 'Wine', icon: Wine },
    { name: 'Billiards', icon: Target },
    { name: 'Tennis', icon: tennisRacket, isLabIcon: true },
    { name: 'Walking My Dog', icon: Dog },
    { name: 'Coding', icon: Code },
    { name: 'Writing', icon: PenLine },
    { name: 'Golf', icon: golfDriver, isLabIcon: true, isBeta: true }
  ];

  return (
    <Box
      component="section"
      id="hobbies"
      className="hobbies-section"
    >
      <Typography variant="h2">
        Hobbies & Interests
      </Typography>

      <Carousel>
        {hobbies.map((hobby, index) => (
          <HobbyCard
            key={index}
            icon={hobby.icon}
            name={hobby.name}
            isBeta={hobby.isBeta}
            isLabIcon={hobby.isLabIcon}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default Hobbies;