// ContactSection.js
import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, Divider } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import emailjs from "emailjs-com";

// Changed to use forwardRef for scroll functionality
const ContactSection = React.forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "name":
        if (!value.match(/^[a-zA-Z\s]+$/)) {
          return "Name should only contain letters and spaces.";
        }
        break;
      case "company":
        if (!value.trim()) {
          return "Company is required.";
        }
        break;
      case "email":
        if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          return "Please enter a valid email.";
        }
        break;
      default:
        return "";
    }
    return "";
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (errors[name]) {
      const newError = validateField(name, value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: newError }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    const nameError = validateField("name", formData.name);
    if (nameError) newErrors.name = nameError;

    const companyError = validateField("company", formData.company);
    if (companyError) newErrors.company = companyError;

    const emailError = validateField("email", formData.email);
    if (emailError) newErrors.email = emailError;

    setErrors(newErrors);

    if (Object.values(newErrors).some((err) => err !== "")) {
      return;
    }

    console.log("Form validated successfully:", formData);

    emailjs
      .send(
        "service_ta5yo2d",
        "template_ppfvh48",
        {
          to_name: "William",
          from_name: formData.name,
          company: formData.company,
          email: formData.email,
          message: formData.message,
        },
        "H9FJtuvnDmqMMf6BJ"
      )
      .then(
        (result) => {
          console.log("EmailJS result:", result.text);
          alert("Message sent successfully!");
          setFormData({ name: "", company: "", email: "", message: "" });
        },
        (error) => {
          console.error("EmailJS error:", error.text);
          alert("Oops, something went wrong... please try again.");
        }
      );
  };

  useEffect(() => {
    // Dynamically load Calendly CSS if not loaded
    const existingCSS = document.querySelector(
      'link[href="https://assets.calendly.com/assets/external/widget.css"]'
    );
    if (!existingCSS) {
      const linkEl = document.createElement("link");
      linkEl.rel = "stylesheet";
      linkEl.href = "https://assets.calendly.com/assets/external/widget.css";
      document.head.appendChild(linkEl);
      console.log("Calendly CSS loaded dynamically.");
    }

    // Dynamically load Calendly JS if not loaded
    const existingScript = document.querySelector(
      'script[src="https://assets.calendly.com/assets/external/widget.js"]'
    );
    if (!existingScript) {
      const scriptEl = document.createElement("script");
      scriptEl.src = "https://assets.calendly.com/assets/external/widget.js";
      scriptEl.async = true;
      scriptEl.onload = () => {
        console.log("Calendly script has loaded successfully!");
      };
      document.body.appendChild(scriptEl);
      console.log("Calendly JS loaded dynamically.");
    }
  }, []);

  const openCalendlyPopup = () => {
    console.log("Button was clicked. Checking Calendly...");

    if (!window.Calendly) {
      console.warn("Calendly script not loaded or blocked!");
      return;
    }
    console.log("Calendly is defined. Opening popup now...");
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/keck-william?hide_gdpr_banner=1&background_color=eaf2f8&text_color=34495e&primary_color=2980b9",
    });
  };

  return (
    <Box
      ref={ref}
      component="section"
      className="contact-section"
      id="contact-section"
      sx={{
        backgroundColor: "var(--color-lightest)",
        padding: "3rem 1rem 3rem",
        textAlign: "center",
      }}
    >
      <Typography variant="h2" sx={{ color: 'var(--color-dark)' }}>Get in Touch</Typography>

      <form
        onSubmit={handleSubmit}
        style={{ maxWidth: "500px", margin: "0 auto" }}
      >
        {["name", "company", "email", "message"].map((field, idx) => (
          <TextField
            key={idx}
            fullWidth
            label={field.charAt(0).toUpperCase() + field.slice(1)}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            multiline={field === "message"}
            minRows={field === "message" ? 3 : undefined}
            error={Boolean(errors[field])}
            helperText={errors[field]}
          />
        ))}

        {/* Use our new "solidAccent" variant for the main CTA button */}
        <Button
          type="submit"
          variant="solidAccent" 
          sx={{ marginTop: "1rem" }}
        >
          Let's Chat!
        </Button>
      </form>

      <Divider
        sx={{
          margin: "2rem auto",
          maxWidth: "500px",
        }}
      >
        <Typography
          component="span"
          sx={{
            backgroundColor: "var(--color-card-background)",
            padding: "0 1rem",
            color: "var(--color-darkest)",
          }}
        >
          or
        </Typography>
      </Divider>

      <Box display="flex" justifyContent="center" gap={2}>
        <Button
          type="button"
          onClick={openCalendlyPopup}
          variant="ghostPrimary"
          sx={{
            whiteSpace: 'nowrap',
          }}
          startIcon={<CalendarTodayIcon />}
        >
          Schedule a Call
        </Button>

        {/* "ghostPrimary" variant for LinkedIn button */}
        <Button
          href="https://www.linkedin.com/in/williamkeck"
          target="_blank"
          variant="ghostPrimary"
          startIcon={<LinkedInIcon />}
        >
          LinkedIn
        </Button>
      </Box>
    </Box>
  );
});

export default ContactSection;