import React from 'react';
import SharedDrawer from '../Shared/SharedDrawer';

const ArticleDrawer = ({ open, onClose, article }) => {
  if (!article) return null;

  return (
    <SharedDrawer
      open={open}
      onClose={onClose}
      title={article.data.title}
      content={article.content}
      image={article.data.image}
    />
  );
};

export default ArticleDrawer;