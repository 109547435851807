// src/utils/bookAPI.js
export const fetchBookDetails = async (isbn) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`
      );
      const data = await response.json();
      
      if (!data.items || !data.items[0]) {
        throw new Error('Book not found');
      }
  
      const bookInfo = data.items[0].volumeInfo;
      
      return {
        title: bookInfo.title,
        author: bookInfo.authors ? bookInfo.authors[0] : 'Unknown Author',
        coverImage: bookInfo.imageLinks ? bookInfo.imageLinks.thumbnail : '/placeholder-book.jpg',
        amazonLink: `https://www.amazon.com/s?k=${encodeURIComponent(bookInfo.title)}`
      };
    } catch (error) {
      console.error(`Error fetching book ${isbn}:`, error);
      return null;
    }
  };