// Hero.js
import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import heroBg from "../../assets/hero-bg.jpeg";
import "./Hero.css";

const Hero = ({ scrollToContact, heroButtonRef }) => {
  const fullText = "Hi, I'm William";
  const tagline = "Never finished. Always under construction."
  const [displayText, setDisplayText] = useState("");
  const [typingComplete, setTypingComplete] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const typingSpeed = 100;
    const typingInterval = setInterval(() => {
      if (currentIndex < fullText.length) {
        setDisplayText((prev) => fullText.slice(0, currentIndex + 1));
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setTypingComplete(true);
      }
    }, typingSpeed);
    return () => clearInterval(typingInterval);
  }, []);

  return (
    <Box className="hero-container">
      <Box className="hero-image-wrapper">
        <img src={heroBg} alt="Hero Background" className="hero-image" />
        <Box className="hero-gradient-overlay" />
      </Box>
      <Box className="hero-content">
        <Typography variant="h1" className="hero-title">
          {displayText}
        </Typography>
        {typingComplete && (
          <>
            <Typography className="hero-tagline" >
              {tagline}
            </Typography>
              <Box className="bottom-container">
                <Button
                  ref={heroButtonRef}
                  className="hero-button"
                  onClick={scrollToContact}
                  variant="heroAccent"
                >
                  Get in Touch
                </Button>
                <Typography className="personal-text">
                  Innovating at the intersection of product, AI and design.
                </Typography>
              </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Hero;