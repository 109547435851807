// src/pages/Home.js
import React, { useRef, useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Hero from "../components/Hero/Hero";
import Resume from "../components/Jobs/Resume";
import theme from "../styles/theme";
import Articles from "../components/Articles/Articles";
import ContactSection from "../components/Contact/ContactSection";
import LifeMap from "../components/Life/LifeMap";
import Hobbies from "../components/Hobbies/Hobbies";
import InterviewSection from "../components/Interview/InterviewSection";
import Books from "../components/Books/Books";
import StickyHeader from "../components/Header/StickyHeader";

const Home = () => {
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const heroRef = useRef(null);
  const contactSectionRef = useRef(null);

  const scrollToContact = () => {
    contactSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Create observers
    const headerObserver = new IntersectionObserver(
      ([entry]) => {
        // When hero section is out of view (scrolled past it)
        // we show the header
        setShowStickyHeader(!entry.isIntersecting);
      },
      {
        // Trigger when the hero section is fully out of view
        threshold: 0,
        rootMargin: '-100px 0px 0px 0px' // Adjust based on hero height
      }
    );

    const contactObserver = new IntersectionObserver(
      ([entry]) => {
        // When contact section comes into view or leaves view
        if (entry.isIntersecting) {
          setShowStickyHeader(false);
        } else {
          // When scrolling up and contact section leaves view,
          // check if we're still below hero before showing header
          const heroRect = heroRef.current?.getBoundingClientRect();
          if (heroRect && heroRect.bottom < 0) {
            setShowStickyHeader(true);
          }
        }
      },
      {
        threshold: 0.1 // Start hiding when 10% of contact section is visible
      }
    );

    // Start observing
    if (heroRef.current) {
      headerObserver.observe(heroRef.current);
    }

    if (contactSectionRef.current) {
      contactObserver.observe(contactSectionRef.current);
    }

    // Cleanup
    return () => {
      headerObserver.disconnect();
      contactObserver.disconnect();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div ref={heroRef}>
        <Hero scrollToContact={scrollToContact} />
      </div>
      <StickyHeader 
        isVisible={showStickyHeader} 
        scrollToContact={scrollToContact} 
      />
      <LifeMap />
      <Articles />
      <InterviewSection />
      <Resume />
      <div ref={contactSectionRef}>
        <ContactSection />
      </div>
      <Hobbies />
      <Books />
    </ThemeProvider>
  );
};

export default Home;