// BookCard.js - Individual Book Card Component
import React from 'react';
import { Typography } from '@mui/material';
import './Books.css';

const BookCard = ({ book }) => {
  const { title, author, status, coverImage } = book;
  
  return (
    <div className="book-card">
      <img
        src={coverImage}
        alt={title}
        className="book-cover"
      />
      
      <div className="book-content">
        <Typography 
          variant="body1" 
          className="book-title"
          title={title}
        >
          {title}
        </Typography>
        
        <Typography 
          variant="body2" 
          className="book-author"
          title={author}
        >
          {author}
        </Typography>
        
        <div className={`book-status ${status === 'reading' ? 'reading-status' : 'completed-status'}`}>
          {status === 'reading' ? 'Reading' : 'Finished'}
        </div>
      </div>
    </div>
  );
};

export default BookCard;