import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ArticleDrawer from "./ArticleDrawer";
import ArticleCard from "./ArticleCard";
import Carousel from "../Shared/Carousel";
import "./Articles.css";
import { loadArticles } from "../../utils/loadArticles";

const Articles = () => {
  const [articlesData, setArticlesData] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchArticles = async () => {
      const articles = await loadArticles();
      setArticlesData(articles);
    };
    fetchArticles();
  }, []);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedArticle(null);
  };

  return (
    <Box component="section" id="articles" className="articles-section">
      <Typography variant="h2">Product Perspectives</Typography>
      <Box className="articles-grid">
        {articlesData.length > 0 ? (
          <Carousel className="articles-carousel">
            {articlesData.map((article, index) => (
              <ArticleCard
                key={index}
                article={article}
                onClick={() => handleArticleClick(article)}
              />
            ))}
          </Carousel>
        ) : (
          <p className="articles-empty">No articles available. Please add some via the CMS.</p>
        )}
      </Box>
      <ArticleDrawer
        open={drawerOpen}
        onClose={handleCloseDrawer}
        article={selectedArticle}
      />
    </Box>
  );
};

export default Articles;